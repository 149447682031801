import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to Bunny Dating!</h1>
        <p>Connect, chat, and meet new people today!</p>
        <button onClick={() => alert("Start Matching!")}>
          Start Matching
        </button>
      </header>
    </div>
  );
}

export default App;
